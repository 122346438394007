<script setup>
import LeaderboardUserCard from "@/components/LeaderboardUserCard.vue";
</script>

<template>
  <div class="homepage-panel">
    <div class="homepage-panel-header">
      <h3>Mileage Leaderboard</h3>
      <b-icon
        class="sync-icon"
        icon="arrow-repeat"
        aria-hidden="true"
        animation="spin"
        v-if="syncing"
      ></b-icon>
    </div>
    <div class="homepage-panel-body">
      <LeaderboardUserCard
        v-for="(item, index) in leaderboardList"
        :key="index"
        :leaderboard-index="index"
        :leaderboard-data="item"
      ></LeaderboardUserCard>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leaderboardList: Array,
    syncing: Boolean,
  },
};
</script>

<style scoped>
.sync-icon {
  position: absolute;
  top: 20px;
  left: 25px;
}
</style>
