<template>
  <b-container>
    <div class="login-container border border-dark rounded p-2 w-50">
      <h1>Login</h1>
      <b-form @submit="registerUser">
        <b-form-group
          id="input-group-username"
          label="Username"
          label-for="input-username"
          ><b-form-input
            id="input-username"
            v-model="form.username"
            placeholder="Enter username"
            class="centered"
            align-v="center"
            required
          ></b-form-input
        ></b-form-group>
        <b-form-group
          id="input-group-email"
          label="Email"
          label-for="input-email"
        >
          <b-form-input
            id="input-email"
            v-model="form.email"
            placeholder="Enter email"
            class="centered"
            align-v="center"
            type="email"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-display-name"
          label="Display Name"
          label-for="input-display-name"
        >
          <b-form-input
            id="input-display-name"
            v-model="form.full_name"
            placeholder="Enter display name"
            class="centered"
            align-v="center"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-password"
          label="Password"
          label-for="input-password"
        >
          <b-form-input
            id="input-password"
            v-model="form.password"
            type="password"
            placeholder="Enter password"
            class="centered"
            required
          ></b-form-input
        ></b-form-group>
        <b-form-group
          id="input-group-invite"
          label="Invite Code"
          lavel-form="input-invite"
          ><b-form-input
            id="input-invite"
            v-model="form.invite"
            placeholder="Enter invitation code"
            class="centered"
            required
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Login</b-button>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import { useAuthStore } from "@/store/auth.store";

export default {
  name: "RegisterView",
  data() {
    return {
      form: {
        username: "",
        email: "",
        full_name: "",
        password: "",
        invite: "",
      },
    };
  },
  methods: {
    async registerUser(event) {
      event.preventDefault();
      console.log("Registering User");
      useAuthStore().register(
        this.form.username,
        this.form.email,
        this.form.full_name,
        this.form.password,
        this.form.invite
      );
    },
  },
};
</script>
<style scoped>
.login-container {
  max-width: 50rem;
  margin: 50px auto 50px auto;
  width: 50%;
  min-width: 350px;
}

.centered {
  margin: auto;
  width: 50%;
}
</style>
