<template>
  <b-container><login-panel></login-panel></b-container>
</template>

<script>
import LoginPanel from "@/components/LoginPanel.vue";

export default {
  components: { "login-panel": LoginPanel },
};
</script>

<style scoped>
#login-panel {
  margin: auto;
  width: 50%;
}
</style>
