<script setup>
import TripCard from "@/components/Home/TripCard.vue";
</script>

<template>
  <div class="homepage-panel trips-panel">
    <div class="homepage-panel-header">
      <h3>Trips</h3>
      <b-icon
        class="sync-icon"
        icon="arrow-repeat"
        aria-hidden="true"
        animation="spin"
        v-if="syncing"
      ></b-icon>
    </div>
    <div class="homepage-panel-body">
      <TripCard
        v-for="(item, index) in tripList.slice().reverse()"
        :key="index"
        :trip-index="index"
        :trip-data="item"
      ></TripCard>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tripList: Array,
    syncing: Boolean,
  },
};
</script>

<style scoped>
.sync-icon {
  position: absolute;
  top: 20px;
  left: 25px;
}
.trips-panel {
  margin-top: 20px;
}
</style>
