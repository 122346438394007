<template>
  <b-container class="traction-card-container">
    <b-row class="traction-card"
      ><b-col class="left-col">
        <label class="loco-index-label"
          ><b>{{ tractionIndex + 1 }}.</b></label
        ><label>{{ tractionData.loco_class }} </label><br /><label
          >{{ tractionData.loco_number }}<i> {{ tractionData.loco_name }} </i
          ><b-link :to="link" title="Loco Information">
            <b-icon icon="info-circle" aria-hidden="true"></b-icon></b-link
        ></label> </b-col
      ><b-col class="right-col">
        <label>{{ tractionData.loco_mileage }} miles</label>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    tractionIndex: Number,
    tractionData: Object,
  },
  data() {
    return {
      link: `/traction/${this.tractionData.loco_type}/${this.tractionData.loco_number}`,
    };
  },
};
</script>

<style scoped>
.traction-card-container {
  border-top: 1px solid grey;
  border-top-left-radius: 15px;
}
.left-col {
  text-align: left;
}
.right-col {
  text-align: right;
}
.traction-card {
  text-align: left;
}
.loco-index-label {
  margin-right: 5px;
}
</style>
