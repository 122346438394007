<script setup>
import DistanceCalculator from "@/components/DistanceCalculator.vue";
</script>

<template>
  <div class="distance">
    <b-container>
      <h1>Distance Calculator</h1>
      <DistanceCalculator
        :calculated-miles.sync="calculatedMiles"
        :calculated-route.sync="calculatedRoute"
        :cumulative-miles.sync="cumulativeMiles"
      ></DistanceCalculator>
      <div>
        <label><b>Mileage: </b><span> </span> </label>
        <label> {{ calculatedMiles.toFixed(2) }} miles</label><br />
        <label><b>Miles/Chains: </b><span> </span> </label>
        <label
          >{{ Math.trunc(calculatedMiles.toFixed(2)) }} miles<span> </span>
          {{ Math.floor(80 * (calculatedMiles.toFixed(2) % 1)) }} chains </label
        ><br />
        <b-row>
          <b-col><b>Route</b></b-col>
          <b-col><b>Miles</b></b-col>
          <b-col><b>Miles/Chains</b></b-col>
        </b-row>
        <b-row v-for="(location, index2) in calculatedRoute" :key="index2">
          <b-col>{{ location }}</b-col>
          <b-col>{{ cumulativeMiles[index2].toFixed(2) }}</b-col>
          <b-col
            >{{ Math.trunc(cumulativeMiles[index2].toFixed(2)) }} miles<span
            > </span>
            {{ Math.floor(80 * (cumulativeMiles[index2].toFixed(2) % 1)) }}
            chains
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "DistanceCalculator",
  data() {
    return {
      calculatedMiles: 0,
      calculatedRoute: [],
      cumulativeMiles: [],
    };
  },
};
</script>
<style scoped>
.row {
  border-bottom: 2px solid gray;
}
</style>
