<template>
  <b-container class="leaderboard-card-container">
    <b-row class="leaderbord-user-card">
      <b-col class="left-col"
        ><label class="leaderboard-index-label"
          ><b>{{ leaderboardIndex + 1 }}.</b></label
        ><b-link
          :to="`/user/${this.leaderboardData.user_id}`"
          title="User Profile"
          ><label>{{ leaderboardData.user_full_name }} </label></b-link
        >
      </b-col>

      <b-col class="right-col"
        ><label> {{ leaderboardData.mileage.toFixed(2) }} miles</label></b-col
      >
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    leaderboardData: Object,
    leaderboardIndex: Number,
  },
};
</script>

<style scoped>
.leaderboard-card-container {
  border-top: 1px solid grey;
  border-top-left-radius: 15px;
}
.left-col {
  text-align: left;
}
.right-col {
  text-align: right;
}
.leaderboard-user-card {
  text-align: justify;
}

.leaderboard-index-label {
  margin-right: 5px;
}
</style>
