<template>
  <div class="card">
    <div class="card-header" :id="returnedCardHeading" @click="toggle">
      <h5 class="mb-0">
        <button
          class="btn"
          data-toggle="collapse"
          :data-target="this.dataTarget"
          aria-expanded="true"
          :aria-controls="returnedMainCardId"
        >
          <b-row>
            <b-col cols="md">
              {{
                offset_time.toLocaleString("en-GB", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
            </b-col>
            <b-col>
              <b>{{ from }}</b>
            </b-col>
            <b-col> > </b-col>
            <b-col
              ><b>{{ to }}</b></b-col
            >
            <b-col cols="md">
              <i v-for="(loco, index) in this.locos" :key="index">
                <i :class="loco.alloc_powering ? '' : 'text-muted'">
                  <span v-if="index != 0">/</span>
                  {{ loco.loco_current_no }}
                </i>
              </i>
            </b-col>
            <b-col> {{ this.miles }} miles </b-col>
          </b-row>
        </button>
      </h5>
    </div>
    <div
      :id="returnedMainCardId"
      :class="this.cardClass"
      :aria-labelledby="this.ariaLabeledBy"
      data-parent="#accordion"
    >
      <div class="card-body">
        <p><b>Mileage:</b> {{ this.miles }}</p>
        <h4><u>Traction</u></h4>
        <p v-for="(loco, index) in this.locos" :key="index">
          <i :class="loco.alloc_powering ? '' : 'text-muted'">
            {{ loco.loco_class }} {{ loco.loco_current_no }}
            <i v-if="loco.loco_name != ''">{{ loco.loco_name }}</i></i
          >
        </p>
        <h4 v-if="gotNotes"><u>Notes</u></h4>
        <p v-if="gotNotes">{{ this.notes }}</p>
        <b-button
          variant="danger"
          class="leg-delete-button"
          :disabled="!this.$props.editMode"
          :hidden="!this.$props.editMode"
          @click="deleteRow"
          >Delete</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { fetchWrapper } from "@/helpers/fetchWrapper";
const fw = fetchWrapper;

export default {
  props: {
    cardIndex: Number,
    cardNumber: Number,
    from: String,
    to: String,
    start: String,
    tz: Number,
    miles: Number,
    notes: String,
    locos: Array,
    editMode: Boolean,
  },
  data() {
    let cardHeading = "heading-" + this.cardNumber;
    let mainCardId = "collapse-" + this.cardNumber;

    var options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    let fixedDate = new Date(this.start).toLocaleString("en-GB", options);
    let start_date = new Date(this.start);
    //console.log(new Date(this.start));
    return {
      returnedCardHeading: cardHeading,
      returnedMainCardId: mainCardId,
      dataTarget: "#" + mainCardId,
      ariaLabeledBy: "#" + cardHeading,
      cardClass: "collapse hide",
      fixedDate: fixedDate,
      date: start_date,
      offset_time: start_date,
      gotNotes: false,
    };
  },
  methods: {
    toggle() {
      //console.log("Toggling");
      if (this.cardClass == "collapse show") {
        this.cardClass = "collapse hide";
      } else {
        this.cardClass = "collapse show";
      }
      //console.log(this.$props.locos);
    },
    deleteRow() {
      console.log({ leg_id: this.cardNumber });
      fw.delete(
        `${process.env.VUE_APP_API_URL}/legs/delete?leg_id=` + this.cardNumber
      ).then((data) => {
        console.log(data);
        this.$emit("remove", this.cardIndex);
      });
    },
  },
  beforeMount() {
    this.offset_time.setTime(
      this.date.getTime() + this.$props.tz * 60 * 60 * 1000
    );
    if (this.$props.notes != "" && this.$props.notes != null) {
      this.gotNotes = true;
    }
    //console.log(this.offset_time);
  },
};
</script>

<style scoped>
.card {
  border: 2px solid grey;
  margin: 5px 0px 5px 0px;
}

.card-header {
  background-color: var(--background-color-secondary);
  transition: background-color 0.5s;
  padding: 2px 5px 2px 5px;
}

.card-body {
  background-color: var(--background-color-secondary);
  transition: background-color 0.5s;
}

.leg-delete-button {
  width: 80px;
  height: 35px;
}

button {
  color: var(--text-primary-color);
  transition: color 0.5s, background-color 0.5s;
  width: 100%;
  padding: 0;
  margin: 0;
}
button:hover {
  color: var(--text-primary-color);
  transition: color 0.5s, background-color 0.5s;
}
</style>
