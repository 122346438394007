import { render, staticRenderFns } from "./TractionInfoCard.vue?vue&type=template&id=6805cabe&scoped=true"
import script from "./TractionInfoCard.vue?vue&type=script&lang=js"
export * from "./TractionInfoCard.vue?vue&type=script&lang=js"
import style0 from "./TractionInfoCard.vue?vue&type=style&index=0&id=6805cabe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6805cabe",
  null
  
)

export default component.exports