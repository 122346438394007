<template>
  <div>
    <h1 v-if="this.$props.addMode">Add New Traction</h1>
    <h1 v-else>Edit Traction</h1>
    <div class="update-form-container">
      <b-form-group label="Type"
        ><b-form-select
          v-model="updateForm.loco_type"
          :options="typeSelectionList"
          class="loco-input-box"
          >Select a type</b-form-select
        >
      </b-form-group>
      <b-form-group
        id="input-group-traction-number"
        label="Number"
        label-for="input-traction-number"
      >
        <b-form-input
          id="input-traction-number"
          v-model="updateForm.loco_number"
          class="centered input-form-text"
          required
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-traction-name"
        label="Name"
        label-for="input-traction-name"
      >
        <b-form-input
          id="input-traction-name"
          v-model="updateForm.loco_name"
          class="centered input-form-text"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-traction-class"
        label="Class"
        label-for="input-traction-class"
      >
        <b-form-input
          id="input-traction-class"
          v-model="updateForm.loco_class"
          class="centered input-form-text"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-traction-operator"
        label="Operator"
        label-for="input-traction-operator"
      >
        <b-form-input
          id="input-traction-operator"
          v-model="updateForm.loco_operator"
          class="centered input-form-text"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-traction-notes"
        label="Notes"
        label-for="input-traction-notes"
      >
        <b-form-input
          id="input-traction-notes"
          v-model="updateForm.loco_notes"
          class="centered input-form-text"
        >
        </b-form-input>
      </b-form-group>

      <b-button
        v-if="this.$props.addMode"
        id="submit-traction"
        @click="addTraction"
        variant="primary"
        title="Submit"
      >
        Submit
      </b-button>
      <b-button
        v-else
        id="submit-traction"
        @click="submitTraction"
        variant="primary"
        title="Submit"
      >
        Submit
      </b-button>
    </div>

    <b-alert v-model="alertShow" :variant="alertVariant" dismissible
      >{{ this.alertText }}
    </b-alert>
  </div>
</template>

<script>
import { fetchWrapper } from "@/helpers/fetchWrapper";

export default {
  name: "EditLocoPanel",
  props: {
    tractionType: String,
    tractionNumber: String,
    tractionClass: String,
    addMode: Boolean,
  },
  data() {
    return {
      typeSelectionList: [
        { value: "S", text: "Steam" },
        { value: "D", text: "Diesel/Electric" },
        { value: "U", text: "Unit" },
      ],
      updateForm: {
        loco_id: null,
        loco_type: null,
        loco_number: null,
        loco_name: null,
        loco_class: null,
        loco_operator: null,
        loco_notes: null,
      },
      alertShow: false,
      alertVariant: "danger",
      alertText:
        "There is no alert, you shouldn't be able to see this at the moment.",
    };
  },
  methods: {
    async submitTraction() {
      fetchWrapper
        .post(`${process.env.VUE_APP_API_URL}/loco/update`, this.updateForm)
        .then((data) => {
          console.log(data);
          this.alertText = "Loco Updated";
          this.alertVariant = "success";
          this.alertShow = true;
        });
    },
    async addTraction() {
      fetchWrapper
        .put(`${process.env.VUE_APP_API_URL}/loco/new`, this.updateForm)
        .then((data) => {
          console.log(data);
          this.alertText = "Loco Added";
          this.alertVariant = "success";
          this.alertShow = true;
        });
    },
    async getLocoInformation() {
      this.gotListData = false;
      fetchWrapper
        .get(
          `${process.env.VUE_APP_API_URL}/loco/user/${this.$props.tractionType}/${this.$props.tractionNumber}`
        )
        .then((data) => {
          this.updateForm.loco_id = data[0].loco_id;
          this.updateForm.loco_type = data[0].loco_type;
          this.updateForm.loco_name = data[0].loco_name;
          this.updateForm.loco_number = data[0].loco_current_no;
          this.updateForm.loco_previous_nos = data[0].loco_previous_nos;
          this.updateForm.loco_class = data[0].loco_class;
          this.updateForm.loco_operator = data[0].loco_operator;
          this.updateForm.loco_notes = data[0].loco_notes;
          //console.log(this.locoData);
        })
        .catch((rej) => {
          //console.log(rej);
          this.error = rej;
        });
    },
  },
  mounted() {
    if (!this.$props.addMode) {
      this.getLocoInformation();
    }
    if (this.$props.addMode && this.$props.tractionClass) {
      this.updateForm.loco_class = this.$props.tractionClass;
    }
  },
};
</script>

<style lang="css" scoped>
select,
input {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color);
  border: none;
}
input:focus {
  background-color: var(--background-color-tirtiary);
  color: var(--text-primary-color);
}
</style>
