<template>
  <b-container
    v-bind:class="
      traction.loco_had
        ? 'had traction-card-container'
        : 'traction-card-container'
    "
  >
    <b-row>
      <b-col cols="md">{{ traction.loco_class }}</b-col>
      <b-col cols="md"
        ><b>{{ traction.loco_number }}</b></b-col
      >
      <b-col cols="md"
        ><i>{{ traction.loco_name }}</i></b-col
      >
      <b-col cols="md">{{ traction.loco_mileage }} <i>mi</i></b-col>
      <b-col cols="md">{{ traction.loco_journeys }} <i>trips</i></b-col>
      <b-col cols="1"
        ><b-link :to="link" title="Loco Information">
          <b-icon
            icon="info-circle"
            aria-hidden="true"
          ></b-icon></b-link></b-col
      ><b-col v-if="elevation" cols="1"
        ><b-link :to="`${link}/edit`" title="Loco Information">
          <b-icon icon="pencil" aria-hidden="true"></b-icon></b-link
      ></b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    traction: Object,
    elevation: Boolean,
  },
  data() {
    return {
      link: `/traction/${this.traction.loco_type}/${this.traction.loco_number}`,
    };
  },
};
</script>

<style scoped>
.traction-card-container {
  margin: 5px 0 5px 0;
  padding: 5px;
  background: var(--background-color-secondary);
  border: 2px solid var(--background-color-tirtiary);
  overflow: hidden;

  transition: color 0.5s, background 0.5s, border 0.5s;
}
.had {
  background: linear-gradient(
    -45deg,
    var(--had-primary-color) 50%,
    var(--background-color-secondary) 50%
  );
  mask-image: linear-gradient(-45deg, black 50%, black 50%);
  transition: color 0.5s, background 0.5s, border 0.5s;
}
.had a {
  color: var(--link-colour);
}
.had a:hover {
  color: var(--alt-accent-hover);
}
</style>
