import { render, staticRenderFns } from "./LegsPanel.vue?vue&type=template&id=4f85d586&scoped=true"
import script from "./LegsPanel.vue?vue&type=script&setup=true&lang=js"
export * from "./LegsPanel.vue?vue&type=script&setup=true&lang=js"
import style0 from "./LegsPanel.vue?vue&type=style&index=0&id=4f85d586&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f85d586",
  null
  
)

export default component.exports