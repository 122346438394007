<template>
  <div class="home">
    <b-container v-if="gotData">
      <div id="header">
        <h1>{{ username }}</h1>
        <label><b>Total Mileage: </b></label> <span></span>
        <label
          ><i id="mileage-val">{{ total_mileage }}</i> miles</label
        ><br />
        <label><b>This year: </b></label> <span></span>
        <label
          ><i id="yearly-mileage">{{ getYearlyMileage() }}</i> miles</label
        >
      </div>
      <div class="desktop-grid">
        <b-row>
          <b-col cols="lg"
            ><TopTractionsCard
              :traction-list="top_locos"
              :syncing="syncing"
            ></TopTractionsCard
          ></b-col>

          <b-col
            ><LeaderboardCard
              :leaderboard-list="leaderboard_data"
              :syncing="syncing"
            ></LeaderboardCard>
            <TripsPanel :trip-list="trips" :syncing="syncing"></TripsPanel
          ></b-col>
        </b-row>
      </div>
    </b-container>
    <b-container v-else
      ><b-icon icon="arrow-repeat" aria-hidden="true" animation="spin"></b-icon
    ></b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { storeToRefs } from "pinia";
import TopTractionsCard from "@/components/TopTractionsCard.vue";
import LeaderboardCard from "@/components/LeaderboardCard.vue";
import TripsPanel from "@/components/Home/TripsPanel.vue";
import { useRailStore } from "@/store/rail.store";
import { useClientStore } from "@/store/client.store";

export default {
  name: "HomeView",
  components: {
    TopTractionsCard,
    LeaderboardCard,
    TripsPanel,
  },
  data() {
    const {
      updating,
      gotHomepageData,
      username,
      top_locos,
      totalMileage,
      leaderboard_data,
      yearly_mileage,
      trips,
    } = storeToRefs(useRailStore());
    const { client_mobile } = storeToRefs(useClientStore());
    return {
      isMobileVar: false,
      client_mobile: client_mobile,
      gotData: gotHomepageData,
      username: username,
      total_mileage: totalMileage,
      top_locos: top_locos,
      leaderboard_data: leaderboard_data,
      yearly_mileage: yearly_mileage,
      trips: trips,
      windowWidth: window.innerWidth,
      syncing: updating,
    };
  },

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.isMobileVar = true;
        this.client_mobile = true;
        return true;
      } else {
        this.client_mobile = false;
        return false;
      }
    },
    async getHomepageData() {
      useRailStore().updateHomepageData();
    },
    getYearlyMileage() {
      if (this.yearly_mileage.length > 0) {
        return this.yearly_mileage[
          this.yearly_mileage.findIndex(function (obj) {
            return obj.year == new Date().getFullYear();
          })
        ].mileage;
      }
      return 0;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 992) {
        this.isMobileVar = true;
      } else {
        this.isMobileVar = false;
      }
    },
  },
  watch: {
    user_data: function () {
      this.username = this.user_data.full_name;
    },
  },
  beforeMount() {
    this.getHomepageData().then(() => {
      this.syncing = true;
      console.log(this.syncing);
    });
    this.isMobile();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
