<script setup>
import EditModePanel from "@/components/LocoInfo/EditModePanel.vue";
import PaginatedVSelect from "@/components/PaginatedVSelect.vue";
import TractionInfoCard from "@/components/TractionInfoCard.vue";
import InfoPanel from "@/components/LocoInfo/InfoPanel.vue";
</script>

<template>
  <div>
    <b-container v-if="addMode">
      <EditModePanel
        :add-mode="true"
        :traction-class="selectedClass"
      ></EditModePanel>
    </b-container>
    <div v-else>
      <h1>Traction Information</h1>
      <div class="filter-panel">
        <b-row>
          <b-col cols="lg" class="filter-column">
            <PaginatedVSelect
              :values="classList"
              label="Filter Class"
              :selected.sync="selectedClass"
            ></PaginatedVSelect>
          </b-col>
          <b-col cols="lg" class="filter-column">
            <b-row>
              <b-col cols="4">
                <label>Number: </label>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  size="sm"
                  v-model="filterNumber"
                  class="w-100"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="lg" class="filter-column">
            <b-button
              href="#   "
              variant="primary"
              @click="applyFilter"
              title="Filter Traction"
              >Search</b-button
            >
          </b-col>
        </b-row>
        <b-row
          ><b-col cols="lg" v-if="elevated" class="filter-column"
            ><b-button
              variant="secondary"
              @click="redirectAddNew"
              title="Add Traction"
              ><b-icon icon="plus-lg" aria-hidden="true"></b-icon> New Loco
            </b-button></b-col
          ></b-row
        >
      </div>
      <div style="margin: 15px">
        <b-row v-if="classMileage">
          <b-col cols="lg"
            ><span><b>Class Mileage: </b></span
            ><span>{{ this.classMileage }}</span></b-col
          >
        </b-row>
      </div>
      <b-container class="loco-info" v-if="gotSingleData">
        <div v-if="editMode">
          <EditModePanel
            :traction-number="this.tractionNumber"
            :traction-type="this.tractionType"
            :add-mode="false"
          ></EditModePanel>
        </div>

        <div v-else>
          <InfoPanel
            :traction-number="this.tractionNumber"
            :traction-type="this.tractionType"
          ></InfoPanel>
        </div>
      </b-container>
      <b-container v-else-if="gotListData">
        <TractionInfoCard
          v-for="(loco, index) in this.dataList"
          :key="index"
          :traction="loco"
          :elevation="elevated"
        ></TractionInfoCard>
      </b-container>
      <b-container v-else-if="error"
        ><h3>{{ this.error }}</h3></b-container
      >
      <b-container v-else
        ><b-icon
          icon="arrow-repeat"
          aria-hidden="true"
          animation="spin"
        ></b-icon>
      </b-container>
    </div>
  </div>
</template>

<script>
import { fetchWrapper } from "@/helpers/fetchWrapper";

export default {
  name: "LocoInfoView",
  data() {
    return {
      tractionType: this.$route.params.type,
      tractionNumber: this.$route.params.number,
      editMode: this.$route.path.endsWith("edit"),
      addMode: this.$route.path.endsWith("new"),
      selectedClass: null,
      filterNumber: null,
      locoData: null,
      gotSingleData: false,
      gotListData: false,
      elevated: this.getUserPrivs(),
      error: null,
      leaderboardList: [],
      classList: [],
      dataList: [],
      classMileage: null,
    };
  },
  watch: {
    "$route.path": {
      handler: function () {
        this.editMode = this.$route.path.endsWith("edit");
        this.addMode = this.$route.path.endsWith("new");
        console.log(this.$route);
        if (
          this.dataList.length > 0 &&
          !this.$route.params.number &&
          !this.addMode
        ) {
          this.gotListData = true;
        }
      },
      deep: true,
      immediate: true,
    },
    "$route.params.type": {
      handler: function (type) {
        this.tractionType = type;
      },
      deep: true,
      immediate: true,
    },
    "$route.params.number": {
      handler: function (number) {
        this.gotSingleData = false;
        console.log(number);
        if (number != null) {
          this.tractionNumber = number;

          this.gotSingleData = true;
        } else if (this.selectedClass != null && this.addMode == true) {
          console.log(`Creating loco with class: ${this.selectedClass}`);
        } else if (this.selectedClass != null || this.filterNumber != null) {
          this.getTraction(this.selectedClass, this.filterNumber);
        } else {
          this.gotSingleData = false;
          this.getAllHadTraction();
        }
      },
      deep: true,
      immediate: true,
    },
    selectedClass: function () {
      if (this.tractionType == null && this.tractionNumber == null) {
        this.getTraction(this.selectedClass, this.filterNumber);
      }
    },
  },
  methods: {
    applyFilter() {
      console.log(this.selectedClass, this.filterNumber);
      this.getTraction(this.selectedClass, this.filterNumber);
    },
    async redirectAddNew() {
      this.addMode = true;
      console.log(this.addMode);
      this.$router.push({ path: "/traction/new" });
      console.log(this.$route);
    },
    async getClassList() {
      fetchWrapper
        .get(`${process.env.VUE_APP_API_URL}/loco/classlist`)
        .then((data) => {
          this.classList = data;
          //console.log(this.locoData);
        })
        .catch((rej) => {
          //console.log(rej);
          this.error = rej;
        });
    },

    async getAllHadTraction() {
      fetchWrapper
        .get(`${process.env.VUE_APP_API_URL}/loco/mileage`)
        .then((data) => {
          this.dataList = data;

          this.gotListData = true;
          //console.log(this.leaderboardList);
        })
        .catch((rej) => {
          //console.log(rej);
          this.error = rej;
        });
    },
    async getTraction(filter_class = null, filter_number = null) {
      if (this.$route.path != "/traction") {
        console.log(this.$route.path);
        this.$router.push({ name: "traction" });
      }
      this.tractionNumber = null;
      this.tractionType = null;
      this.gotListData = false;
      this.gotSingleData = false;
      this.error = null;
      this.classMileage = null;
      if (filter_number && filter_class) {
        console.log("Running class & number search");
        fetchWrapper
          .get(
            `${process.env.VUE_APP_API_URL}/loco/by-class/${encodeURI(
              filter_class
            )}/${filter_number}`
          )
          .then((data) => {
            this.dataList = data;
            this.gotListData = true;
          })
          .catch((rej) => {
            this.error = rej;
          });
      } else if (filter_number) {
        fetchWrapper
          .get(`${process.env.VUE_APP_API_URL}/loco/${filter_number}`)
          .then((data) => {
            this.dataList = data;
            this.gotListData = true;
            //console.log(this.leaderboardList);
          })
          .catch((rej) => {
            //console.log(rej);
            this.error = rej;
          });
      } else if (filter_class) {
        fetchWrapper
          .get(
            `${
              process.env.VUE_APP_API_URL
            }/loco/getclass?loco_class=${encodeURI(
              filter_class
            )}&had_stats=true&order_by_mileage=true`
          )
          .then((data) => {
            this.dataList = data;

            this.gotListData = true;
            //console.log(this.leaderboardList);
          })
          .catch((rej) => {
            //console.log(rej);
            this.error = rej;
          });

        fetchWrapper
          .get(
            `${
              process.env.VUE_APP_API_URL
            }/loco/class/mileage?loco_class=${encodeURI(filter_class)}`
          )
          .then((data) => {
            console.log(data);
            this.classMileage = data.mileage;
          });
      } else {
        this.getAllHadTraction();
      }
    },
    async getUserPrivs() {
      fetchWrapper
        .get(`${process.env.VUE_APP_API_URL}/users/me`)
        .then((data) => {
          console.log(data);
          this.elevated = data.elevated;
        })
        .catch((rej) => {
          //console.log(rej);
          this.error = rej;
        });
    },
  },
  mounted() {
    this.getClassList();
    if (!this.tractionType && !this.tractionNumber) {
      this.getAllHadTraction();
    }
    console.log(this.editMode);
  },
};
</script>

<style scoped>
.update-form-container {
  margin: 15px 0 15px 0;
}
.filter-panel {
  padding: 10px;
  margin: 15px;
  border: 2px solid var(--background-color-tirtiary);
  background-color: var(--background-color-primary);
  transition: color 0.5s, background-color 0.5s;
}
.filter-column {
  margin-bottom: 5px;
}
</style>
