<script setup>
import LegCardVue from "./LegCard.vue";
</script>

<template>
  <div class="homepage-panel">
    <div class="homepage-panel-header">
      <h3>Entries</h3>
      <b-icon
        class="sync-icon"
        icon="arrow-repeat"
        aria-hidden="true"
        animation="spin"
        v-if="syncing"
      ></b-icon>
    </div>
    <div class="homepage-panel-body">
      <LegCardVue
        v-for="(leg, index) in this.$props.legs"
        :key="index"
        :card-index="index"
        v-bind:card-number="leg.leg_id"
        v-bind:from="leg.leg_start"
        v-bind:to="leg.leg_end"
        v-bind:start="leg.leg_begin_time"
        :locos="leg.locos"
        :miles="leg.leg_mileage"
        :editMode="legEditMode"
      ></LegCardVue>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    syncing: Boolean,
    legs: Array,
  },
};
</script>

<style scoped>
.sync-icon {
  position: absolute;
  top: 20px;
  left: 25px;
}
.top-locos {
  border: 2px solid grey;
  border-radius: 10px;
}
.top-locos-header {
  background-color: beige;
  border-radius: 10px 10px 0 0;
  margin-bottom: 10px;
}
.top-locos-body {
  padding: 10px;
}
</style>
