<template>
  <div>
    <b-container v-if="this.$props.gotData">
      <div
        id="accordion"
        v-for="(item, index) in this.$props.legs"
        :key="item.leg_id"
      >
        <leg-card
          :card-index="index"
          v-bind:card-number="item.leg_id"
          v-bind:from="item.leg_start"
          v-bind:to="item.leg_end"
          v-bind:start="item.leg_begin_time"
          :tz="item.leg_timezone"
          :locos="item.locos"
          :miles="item.leg_mileage"
          :editMode="legEditMode"
          v-on:remove="dummyRemove"
        ></leg-card>
      </div>

      <b-button class="load-button" @click="getMoreLegs">Load more</b-button>
    </b-container>
    <div v-else>
      <b-icon icon="arrow-repeat" aria-hidden="true" animation="spin"></b-icon>
    </div>
  </div>
</template>

<script>
import LegCard from "@/components/LegCard.vue";
export default {
  components: {
    "leg-card": LegCard,
  },
  props: {
    legs: Array,
    gotData: Boolean,
  },
  data() {
    return {
      legEditMode: false,
    };
  },
  methods: {
    getMoreLegs() {
      this.$emit("refresh");
    },
    dummyRemove() {
      console.log("This is not run.");
    },
  },
};
</script>

<style lang="css" scoped>
.load-button {
  margin-bottom: 20px;
}
</style>
