<template>
  <b-container class="trip-card-container">
    <b-row class="trip-user-card">
      <b-col class="left-col"
        ><label>{{ tripData.trip_name }} </label>
      </b-col>

      <b-col class="right-col"
        ><label> {{ tripData.trip_mileage.toFixed(2) }} miles</label></b-col
      >
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    tripData: Object,
    tripIndex: Number,
  },
};
</script>

<style scoped>
.trip-card-container {
  border-top: 1px solid grey;
  border-top-left-radius: 15px;
}
.left-col {
  text-align: left;
}
.right-col {
  text-align: right;
}
.trip-user-card {
  text-align: justify;
}

.trip-index-label {
  margin-right: 5px;
}
</style>
