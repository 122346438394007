<script setup>
import TractionCard from "./TractionCard.vue";
</script>

<template>
  <div class="homepage-panel">
    <div class="homepage-panel-header">
      <h3>Top Traction</h3>
      <b-icon
        class="sync-icon"
        icon="arrow-repeat"
        aria-hidden="true"
        animation="spin"
        v-if="syncing"
      ></b-icon>
    </div>
    <div class="homepage-panel-body">
      <TractionCard
        v-for="(loco, index) in this.$props.tractionList"
        :key="index"
        :traction-data="loco"
        :traction-index="index"
      ></TractionCard>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    syncing: Boolean,
    tractionList: Array,
  },
};
</script>

<style scoped>
.sync-icon {
  position: absolute;
  top: 20px;
  left: 25px;
}
.top-locos {
  border: 2px solid grey;
  border-radius: 10px;
}
.top-locos-header {
  background-color: beige;
  border-radius: 10px 10px 0 0;
  margin-bottom: 10px;
}
.top-locos-body {
  padding: 10px;
}
</style>
