import { defineStore } from "pinia";

import { fetchWrapper } from "@/helpers/fetchWrapper";
import { reactive, ref } from "vue";
let fw = fetchWrapper;

export const useRailStore = defineStore({
  id: "rail",
  state: () => ({
    updating: ref(true),
    locations: ref([]),

    leaderboard_data: {},
    totalMileage: 0,
    top_locos: [],
    user_data: reactive({}),
    yearly_mileage: ref([]),
    username: "User",

    gotHomepageData: false,
    gotLegData: false,
    legs: ref([]),
    trips: ref([]),
    addData: {
      validatedTraction: [],
      computedRoutes: [],
    },
  }),
  actions: {
    async updateHomepageData() {
      this.updating = true;
      //console.log(this.updating);
      const {
        locations,
        user_data,
        username,
        top_locos,
        totalMileage,
        yearly_mileage,
        trips,
      } = localStorage;
      if (locations) {
        this.locations = JSON.parse(locations);
      }
      if (user_data != null) {
        this.user_data = JSON.parse(user_data);
      }
      if (username != null) {
        this.username = JSON.parse(username);
      }
      if (top_locos) {
        this.top_locos = JSON.parse(top_locos);
      }
      if (totalMileage && typeof totalMileage != undefined) {
        this.totalMileage = JSON.parse(totalMileage);
      }
      if (yearly_mileage) {
        this.yearly_mileage = JSON.parse(yearly_mileage);
      }
      if (trips) {
        this.trips = JSON.parse(trips);
      }
      await fw
        .get(`${process.env.VUE_APP_API_URL}/stats/homepage`)
        .then((data) => {
          //console.log(data);
          this.leaderboard_data = data.leaderboard_data;
          localStorage.leaderboard_data = JSON.stringify(data.leaderboard_data);
          if (data.milage_data == 0) {
            this.totalMileage = 0;
            localStorage.totalMileage = 0;
          } else {
            if (data.milage_data != null) {
              this.totalMileage = data.milage_data.mileage;
              localStorage.totalMileage = JSON.stringify(
                data.milage_data.mileage
              );
            }
          }
          if (data.top_locos != null) {
            this.top_locos = data.top_locos;
            localStorage.top_locos = JSON.stringify(data.top_locos);
          }
          this.user_data = data.user_data;
          localStorage.setItem("user_data", JSON.stringify(data.user_data));
          if (this.user_data != null) {
            this.username = this.user_data.full_name;
            localStorage.setItem(
              "username",
              JSON.stringify(this.user_data.full_name)
            );
          }
          if (data.yearly_mileage != null) {
            this.yearly_mileage = data.yearly_mileage;
            localStorage.setItem(
              "yearly_mileage",
              JSON.stringify(this.yearly_mileage)
            );
          }
          if (data.trip_data != null) {
            this.trips = data.trip_data;
            localStorage.setItem("trip_data", JSON.stringify(this.trips));
          }
          this.gotHomepageData = true;
          this.updating = false;
        });
    },
    async updateLocations() {
      const { locations } = localStorage;
      if (locations) {
        this.locations = JSON.parse(locations);
      }
      await fw.get(`${process.env.VUE_APP_API_URL}/location`).then((data) => {
        //console.log(data);
        this.locations = data;
        localStorage.setItem("locations", JSON.stringify(data));
      });
    },
    async updateLegs(legDirection) {
      this.gotLegData = false;
      //console.log(process.env.VUE_APP_API_URL);
      await fw
        .get(
          `${process.env.VUE_APP_API_URL}/user/legs?sort_direction=` +
            legDirection
        )
        .then((data) => {
          //console.log(data);
          this.legs = data;
          this.gotLegData = true;
        });
    },
    async submitLeg() {},
  },
});
