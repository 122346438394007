<template>
  <h1>Logging out...</h1>
</template>

<script>
import { useAuthStore } from "@/store/auth.store";
export default {
  name: "LogoutView",
  methods: {
    logout() {
      this.$cookies.remove("access_token");
      this.$cookies.remove("token_type");
    },
  },
  beforeMount() {
    this.logout();
    useAuthStore().returnUrl = null;
    useAuthStore().logout();
  },
};
</script>
