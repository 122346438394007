<script setup>
import vSelect from "vue-select";
</script>

<template>
  <b-row>
    <b-col cols="4">
      <label>{{ this.$props.label }}</label>
    </b-col>
    <b-col cols="8">
      <vSelect
        class="v-select-dropdown"
        :label="label"
        :value="selectedValue"
        :options="paginated"
        :filterable="false"
        @input="updateValue"
        @open="onOpen"
        @close="onClose"
        @search="(query) => (search = query)"
      >
        <template #list-footer
          ><li ref="load" class="loader" v-show="hasNextPage">
            Loading more options...
          </li></template
        ></vSelect
      >
    </b-col>
  </b-row>
</template>

<script>
const Fuse = require("fuse.js");
export default {
  props: {
    values: Array,
    label: String,
    selectId: Number,
    selectedList: Array,
    selected: String,
    initialValue: String,
  },
  data() {
    return {
      observer: null,
      limit: 10,
      search: "",
      selectedValue: this.$props.initialValue,
      offset: 0,
    };
  },
  watch: {
    selectedList: function () {
      this.selectedValue = this.$props.selectedList[this.$props.selectId];
    },
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    updateValue(value) {
      this.selectedValue = value;
      this.$props.selected = value;
      this.$emit("update:selected", value);
    },
    deleteRow() {
      if (this.$props.selectId > -1) {
        // only splice array when item is found
        this.$props.selectedList.splice(this.$props.selectId, 1); // 2nd parameter means remove one item only
      }
    },
    onSearch(query) {
      this.search = query;
      this.offset = 0;
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
    this.selectedValue = this.$props.selected;
  },
  computed: {
    filtered() {
      if (this.search.toLocaleLowerCase() == "") {
        return this.$props.values;
      }

      //let total_match = this.$props.values.filter((item) =>
      //  item.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      //);
      //console.log(total_match);
      let options = {
        includeScore: false,
        keys: ["name"],
        threshold: 0.15,
      };
      const fuse = new Fuse(this.$props.values, options);
      const result = fuse.search(this.search.toLocaleLowerCase());
      let ret_array = result.map(({ item }) => item);
      return ret_array;
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },
};
</script>

<style scoped lang="scss">
@import "vue-select/dist/vue-select.css";
.calc-form-group {
  animation: stretch 0.25s cubic-bezier(1, 0, 0, 1);
  border: 2px solid grey;
  border-radius: 10px;
  padding: 5px;
}
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
button {
  display: inline-block;
  margin: 5px;
}

@keyframes stretch {
  0%,
  10% {
    transform: scale(1, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}
</style>
