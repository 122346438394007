<template>
  <div class="login-container border border-dark rounded p-2 w-50">
    <h1>Login</h1>
    <b-form @submit="authLogin" v-if="show">
      <b-form-group
        id="input-group-username"
        label="Username"
        label-for="input-username"
        ><b-form-input
          id="input-username"
          v-model="form.username"
          placeholder="Enter username"
          class="centered input-form-text"
          align-v="center"
          required
        ></b-form-input
      ></b-form-group>
      <b-form-group
        id="input-group-password"
        label="Password"
        label-for="input-password"
      >
        <b-form-input
          id="input-password"
          v-model="form.password"
          type="password"
          placeholder="Enter password"
          class="centered input-form-text"
          required
        ></b-form-input
      ></b-form-group>
      <b-row class="login-button-row">
        <b-col>
          <b-button @click="registerUser" variant="secondary"
            >Register</b-button
          ></b-col
        >
        <b-col>
          <b-button type="submit" variant="primary">Login</b-button></b-col
        >
      </b-row>
    </b-form>
    <b-alert v-model="alertShow" :variant="alertVariant"
      >{{ this.alertText }}
    </b-alert>
  </div>
</template>

<script>
import { useAuthStore } from "@/store/auth.store";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      show: true,
      access_token: "",
      token_type: "",
      alertShow: false,
      alertVariant: "warning",
      alertText:
        "There is no alert, you shouldn't be able to see this at the moment.",
    };
  },
  methods: {
    async checkToken() {
      await fetch(`${process.env.VUE_APP_API_URL}/validate`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.$cookies.get("access_token"),
        },
        mode: "cors",
        referrerPolicy: "origin",
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          if (data.detail == "Token is valid") {
            this.$router.push({ name: "home" });
          }
        });
    },
    async authLogin(event) {
      event.preventDefault();
      this.alertText = "Logging in...";
      this.alertShow = true;
      useAuthStore().login(this.form.username, this.form.password);
    },
    async fetchToken(event) {
      event.preventDefault();
      let form_result = {
        username: this.form.username,
        password: this.form.password,
      };

      let form_body = [];

      for (var property in form_result) {
        var encodedKey = encodeURIComponent(property);
        var encodeValue = encodeURIComponent(form_result[property]);
        form_body.push(encodedKey + "=" + encodeValue);
      }
      form_body = form_body.join("&");
      const response = await fetch(`${process.env.VUE_APP_API_URL}/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        mode: "cors",
        body: form_body,
        referrerPolicy: "no-referrer",
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          if (data.access_token == undefined) {
            alert("Incorrect username or password");
          } else {
            this.access_token = data.access_token;
            this.token_type = data.token_type;

            // Clear tokens
            this.$cookies.remove("access_token");
            this.$cookies.remove("token_type");

            // Insert new token
            this.$cookies.set("access_token", data.access_token, 60 * 30);
            this.$cookies.set("token_type", data.token_type, 60 * 30);

            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("token_type", data.token_type);
            localStorage.setItem("token_timeout", 60 * 30);

            this.$router.push({ path: "/" });
          }
        });

      return response;
    },
    registerUser() {
      this.$router.push("/register");
    },
  },
  mounted() {},
};
</script>

<style scoped>
.login-container {
  max-width: 50rem;
  margin: 50px auto 50px auto;
  width: 50%;
  min-width: 350px;
}

.login-button-row {
  margin-bottom: 10px;
}

.centered {
  margin: auto;
  width: 50%;
}

.input-form-text {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color);
}
</style>
